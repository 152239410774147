import Axios from "axios";
// Procedure ta hobe eivhabe j 24 ta kore bag, sneakers, shirt, latest products kore daily import kore rakhbo schedule kore dine ekbar. And save the data to firebase firestore.
// kono user ashle firebase theke data ene dekhabo. not api in homepage.
// link search ba keyword search korle direct api theke data ene dekhabo 30 ta 30 ta kore per hit.
// kono category te click korle oitao api theke dekhabo 30 ta 30 ta kore.

const instanceKey = "6417507a-3562-4032-9162-5bc1c8c6e159";
export const batchGetItemFullInfo = async (itemId) => {
  const resultObj = await Axios.get(
    `https://otapi.net/service-json/BatchGetItemFullInfo?instanceKey=${instanceKey}&language=en&signature=&timestamp=&sessionId=&itemParameters=&itemId=abb-${itemId}&blockList=`
  );
  console.log(resultObj.data);
  if (resultObj.data && resultObj.data.Result && resultObj.data.Result.Item) {
    const item = resultObj.data.Result.Item;
    let specs = [];
    if (item.Attributes && item.Attributes.length > 0) {
      item.Attributes.map((attr) => {
        if (specs.find((specsAttr) => specsAttr.name == attr.PropertyName)) {
          specs = specs.map((specsAttr) => {
            if (specsAttr.name == attr.PropertyName) {
              return {
                ...specsAttr,
                value: `${specsAttr.value},${attr.Value}`,
              };
            } else {
              return specsAttr;
            }
          });
        } else {
          specs.push({
            name: attr.PropertyName,
            value: attr.Value,
            IsConfigurator: attr.IsConfigurator,
            PropertyName: attr.PropertyName,
          });
        }
      });
    }
    let props_imgs = [];
    if (item.Attributes && item.Attributes.length > 0) {
      item.Attributes.filter(
        (attr1) => attr1.IsConfigurator && attr1.ImageUrl
      ).map((attr, index) => {
        props_imgs.push({
          properties: `0:${index}`,
          url: attr.ImageUrl,
          propertyName: attr.PropertyName,
          value: attr.Value,
        });
      });
    }
    let props_list = {};
    if (item.Attributes && item.Attributes.length > 0) {
      specs
        .filter((attr1) => attr1.IsConfigurator)
        .map((attr, index) => {
          attr.value.split(",").map((attr2, index2) => {
            props_list = {
              ...props_list,
              [`${index}:${index2}`]: `${attr.PropertyName}:${attr2}`,
            };
            return props_list;
          });
        });
    }

    // props_list2 is only needed to make variant
    let props_list2 = [];
    if (item.Attributes && item.Attributes.length > 0) {
      Object.keys(props_list).forEach((key, index) => {
        item.Attributes.map((attribute) => {
          if (
            `${attribute.PropertyName}:${attribute.Value}` == props_list[key]
          ) {
            props_list2.push({ ...attribute, [key]: props_list[key] });
          }
        });
      });
    }
    let variants = [];
    if (item.ConfiguredItems && item.ConfiguredItems.length > 0) {
      item.ConfiguredItems.map((sku) => {
        let properties = getProperties(sku, props_list2);

        variants.push({
          price: sku.Price.OriginalPrice,
          properties: properties.properties,
          properties_name: properties.propertiesName,
          quantity: sku.Quantity,
          sales: sku.SalesCount,
          sku_id: sku.Id,
          spec_id: sku.Id,
          total_price: 0,
        });
      });
    }

    const product = {
      availability: "pre-order",
      brand: "",
      brandId: "",
      categoryId: item.ExternalCategoryId,
      description: "",
      detail_url: item.ExternalItemUrl,
      feedback: [],
      id: itemId,
      item_weight:
        item.ActualWeightInfo.Weight == 0 ? "" : item.ActualWeightInfo.Weight,
      name: item.Title,
      totalSold: item.FeaturedValues.find((prop) => prop.Name == "TotalSales")
        ? item.FeaturedValues.find((prop) => prop.Name == "TotalSales").Value
        : 355,

      orders: item.FeaturedValues.find(
        (prop) => prop.Name == "SalesInLast30Days"
      )
        ? item.FeaturedValues.find((prop) => prop.Name == "SalesInLast30Days")
            .Value
        : 20,

      pictures: item.Pictures.map((picture) => {
        return picture.Url;
      }),
      price: item.Price.OriginalPrice,
      price_range: JSON.parse(
        `[${item.QuantityRanges.map((quantity) => {
          return `[${quantity.MinQuantity}, ${quantity.Price.OriginalPrice}]`;
        })}]`
      ),
      specs: specs,
      videos: item.Videos,
      salePrice: item.Price.MarginPrice,
      rating: "5.0",
      shop_id: item.VendorId,
      shop_name: item.VendorName,
      shop_rating: item.VendorScore,
      relatedGroups: item.RelatedGroups,
      store: "1688",
      totalAvailableQuantity: "",
      props_list: props_list,
      props_name: "",
      props_imgs: {
        prop_img: props_imgs,
      },
      variants: variants,
    };
    console.log(product);
    console.log(props_list2);
    return product;
  } else {
    return null;
  }
};
const getProperties = (sku, props_list2) => {
  let properties = "";
  let propertiesName = "";
  sku.Configurators.map((config, index) => {
    console.log(config.Pid);
    console.log(config.Vid);
    console.log(props_list2);
    let obj = props_list2.find(
      (prop) => prop.Vid == config.Vid && prop.Pid == config.Pid
    );
    console.log(obj);
    if (obj) {
      let key = Object.keys(obj).filter((key) => key.includes(":"))[0];
      let keyValue = `${key}:${obj[key]}`;
      console.log(key);
      properties = properties + (properties == "" ? "" : ";") + key;
      propertiesName =
        propertiesName + (propertiesName == "" ? "" : ";") + keyValue;
    }
  });
  return { properties, propertiesName };
};

export const getItemDescription = async (itemId) => {
  const resultObj = await Axios.get(
    `https://otapi.net/service-json/GetItemDescription?instanceKey=${instanceKey}&language=&signature=&timestamp=&itemId=abb-${itemId}`
  );
  if (resultObj.data && resultObj.data.OtapiItemDescription) {
    return resultObj.data.OtapiItemDescription.ItemDescription;
  } else {
    return null;
  }
};
export const batchSearchItemsFrameByTitle = async (keyword) => {
  const resultObj = await Axios.get(
    `https://otapi.net/service-json/BatchSearchItemsFrame?instanceKey=${instanceKey}&language=en&signature=&timestamp=&sessionId=&xmlParameters=%3CSearchItemsParameters%3E%3CItemTitle%3E${keyword}%3C%2FItemTitle%3E+%3C%2FSearchItemsParameters%3E&framePosition=1&frameSize=20&blockList=`
  );
  if (resultObj.data && resultObj.data.Result && resultObj.data.Result) {
    console.log(resultObj.data.Result);
    return resultObj.data.Result;
  } else {
    return null;
  }
};
export const batchSearchItemsFrameByCategoryId = async (categoryId) => {
  const resultObj = await Axios.get(
    `https://otapi.net/service-json/BatchSearchItemsFrame?instanceKey=${instanceKey}&language=en&signature=&timestamp=&sessionId=&xmlParameters=%3CSearchItemsParameters%3E%3CCategoryId%3Eabb-${categoryId}%3C%2FCategoryId%3E+%3C%2FSearchItemsParameters%3E&framePosition=21&frameSize=40&blockList=`
  );
  if (resultObj.data && resultObj.data.Result && resultObj.data.Result) {
    let products = [];
    let array = resultObj.data.Result.Items.Items.Content;

    array.map((item) => {
      const product = {
        availability: "pre-order",
        brand: item.BrandName ? item.BrandName : "",
        brandId: item.BrandId ? item.BrandId : "",
        categoryId: item.ExternalCategoryId,
        description: "",
        detail_url: item.ExternalItemUrl,
        feedback: [],
        id: item.Id.replace("abb-", ""),
        item_weight: item.PhysicalParameters.Weight
          ? item.PhysicalParameters.Weight
          : 0,
        name: item.Title,
        totalSold: item.FeaturedValues.find((prop) => prop.Name == "TotalSales")
          ? item.FeaturedValues.find((prop) => prop.Name == "TotalSales").Value
          : 355,

        orders: item.FeaturedValues.find(
          (prop) => prop.Name == "SalesInLast30Days"
        )
          ? item.FeaturedValues.find((prop) => prop.Name == "SalesInLast30Days")
              .Value
          : 20,

        pictures: item.Pictures.map((picture) => {
          return picture.Url;
        }),
        price: item.Price.OriginalPrice,
        price_range:
          item.QuantityRanges && item.QuantityRanges.length > 0
            ? `[${item.QuantityRanges.map((quantity) => {
                return `[${quantity.MinQuantity}, ${quantity.Price.OriginalPrice}]`;
              })}]`
            : "",
        specs: [],
        videos: null,
        salePrice: item.Price.MarginPrice,
        rating: "5.0",
        shop_id: item.VendorId,
        shop_name: item.VendorName,
        shop_rating: item.VendorScore,
        relatedGroups: [],
        store: "1688",
        totalAvailableQuantity: item.MasterQuantity,
        props_list: [],
        props_name: "",
        props_imgs: {
          prop_img: [],
        },
        variants: [],
      };

      products.push(product);
    });
    console.log(array);
    console.log(products);
    return products;
  } else {
    return null;
  }
};
