import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
  MessageCircle,
} from "react-feather";

export const MENUITEMSFORADMIN = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/messages",
    title: "Messages",
    icon: MessageCircle,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Products",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        title: "in stock/pre order",
        type: "sub",
        active: false,
        children: [
          {
            path: "/products/home-category",
            title: "Home category",
            type: "link",
          },
          {
            path: "/products/physical/category",
            title: "Product List",
            type: "link",
          },

          {
            path: "/products/physical/add-aliexpress-product",
            title: "Add Product",
            type: "link",
          },
          {
            path: "/products/physical/banners",
            title: "Banners",
            type: "link",
          },
          {
            path: "/products/physical/intro-modal",
            title: "Intro Modal",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    title: "Coupons",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/coupons/physical/all-coupons",
        title: "All Coupons",
        type: "link",
      },
    ],
  },
  {
    title: "Campaigns",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/coupons/physical/all-campaigns",
        title: "All Campaigns",
        type: "link",
      },
    ],
  },

  {
    title: "1688/taobao Orders",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      { path: "/1688-taobao-orders/new", title: "New Orders", type: "link" },
      {
        path: "/1688-taobao-orders/purchased",
        title: "Purchased",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/received-in-warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/ready-for-fly",
        title: "Ready for fly",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/bangladesh-customs",
        title: "Bangladesh customs",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/custom-released",
        title: "Custom released",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/paicart-warehouse",
        title: "Paicart warehouse",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/rejected",
        title: "Rejected",
        type: "link",
      },
      {
        path: "/1688-orders/partial-payment",
        title: "Partial Payment",
        type: "link",
      },
    ],
  },
  {
    title: "Product Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      { path: "/product-request/new", title: "New Requests", type: "link" },
      {
        path: "/product-request/rates-given",
        title: "Rates Provided",
        type: "link",
      },
      {
        path: "/product-request/purchased",
        title: "Purchased",
        type: "link",
      },
      {
        path: "/product-request/received-in-warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/product-request/ready-for-fly",
        title: "Ready for fly",
        type: "link",
      },
      {
        path: "/product-request/bangladesh-customs",
        title: "Bangladesh customs",
        type: "link",
      },
      {
        path: "/product-request/custom-released",
        title: "Custom released",
        type: "link",
      },
      {
        path: "/product-request/paicart-warehouse",
        title: "Paicart warehouse",
        type: "link",
      },
      {
        path: "/product-request/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/product-request/rejected",
        title: "Rejected",
        type: "link",
      },
    ],
  },
  {
    title: "Shipment Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      { path: "/shipment-request/new", title: "New Request", type: "link" },
      {
        path: "/shipment-request/rates-given",
        title: "Rates Provided",
        type: "link",
      },
      {
        path: "/shipment-request/received-in-warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/shipment-request/ready-for-fly",
        title: "Ready for fly",
        type: "link",
      },

      {
        path: "/shipment-request/bangladesh-customs",
        title: "Bangladesh customs",
        type: "link",
      },
      {
        path: "/shipment-request/custom-released",
        title: "Custom released",
        type: "link",
      },
      {
        path: "/shipment-request/paicart-warehouse",
        title: "Paicart warehouse",
        type: "link",
      },
      { path: "/shipment-request/delivered", title: "Delivered", type: "link" },

      { path: "/shipment-request/rejected", title: "Rejected", type: "link" },
    ],
  },
  {
    title: "Ship for me",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        title: "Air",
        type: "sub",
        active: false,
        children: [
          { path: "/ship-for-me/air-china", title: "China", type: "link" },
          { path: "/ship-for-me/air-india", title: "India", type: "link" },
          { path: "/ship-for-me/air-uk", title: "UK", type: "link" },
          { path: "/ship-for-me/air-usa", title: "USA", type: "link" },
          {
            path: "/ship-for-me/air-hongkong",
            title: "Hongkong",
            type: "link",
          },
          {
            path: "/ship-for-me/air-thailand",
            title: "Thailand",
            type: "link",
          },
        ],
      },
      {
        title: "Sea",
        type: "sub",
        active: false,
        children: [
          { path: "/ship-for-me/sea-china", title: "China", type: "link" },
          { path: "/ship-for-me/sea-uk", title: "UK", type: "link" },
          { path: "/ship-for-me/sea-usa", title: "USA", type: "link" },
          {
            path: "/ship-for-me/sea-hongkong",
            title: "Hongkong",
            type: "link",
          },
          {
            path: "/ship-for-me/sea-thailand",
            title: "Thailand",
            type: "link",
          },
        ],
      },
      {
        path: "/ship-for-me-warehouse",
        title: "Warehouse address",
        type: "link",
      },
    ],
  },
  {
    title: "Payment Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/payment-request/pending",
        title: "Ship for me Request",
        type: "link",
      },
      {
        path: "/payment-request-order/pending",
        title: "Order Request",
        type: "link",
      },

      {
        path: "/refund-request-order/pending",
        title: "Refund Request",
        type: "link",
      },
    ],
  },
  {
    title: "Product to order",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/1688-taobao-orders/paid",
        title: "1688/Taobao Orders",
        type: "link",
      },
      {
        path: "/product-request/paid",
        title: "Product Request",
        type: "link",
      },
    ],
  },

  {
    title: "Notices",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/notices",
        title: "All Notices",
        type: "link",
      },
    ],
  },

  {
    title: "Users",
    icon: UserPlus,
    type: "sub",
    active: false,
    children: [
      { path: "/users/list-user", title: "User List", type: "link" },
      // { path: '/users/create-user', title: 'Create User', type: 'link' },
    ],
  },
  {
    title: "Suppliers",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      {
        path: "/suppliers/list_suppliers",
        title: "Distributed Orders",
        type: "link",
      },
    ],
  },
  {
    title: "Localization",
    icon: Chrome,
    type: "sub",
    children: [
      {
        path: "/localization/currency-rates",
        title: "Currency Converter",
        type: "link",
      },
      {
        path: "/localization/shipping-charges",
        title: "Shipping charges",
        type: "link",
      },
      {
        path: "/localization/shipping-charge/add-product",
        title: "Add Product Shipping charge",
        type: "link",
      },
    ],
  },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  },
  {
    title: "Invoice",
    path: "/invoice",
    icon: Archive,
    type: "link",
    active: false,
  },
  {
    title: "Register a manager",
    path: "/",
    icon: LogIn,
    type: "link",
    active: false,
  },
];

export const MENUITEMSFORAGENT = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/messages",
    title: "Messages",
    icon: MessageCircle,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Products",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        title: "in stock/pre order",
        type: "sub",
        active: false,
        children: [
          {
            path: "/products/home-category",
            title: "Home category",
            type: "link",
          },
          {
            path: "/products/physical/category",
            title: "Product List",
            type: "link",
          },

          {
            path: "/products/physical/add-aliexpress-product",
            title: "Add Product",
            type: "link",
          },
          {
            path: "/products/physical/banners",
            title: "Banners",
            type: "link",
          },
          {
            path: "/products/physical/intro-modal",
            title: "Intro Modal",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    title: "Coupons",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/coupons/physical/all-coupons",
        title: "All Coupons",
        type: "link",
      },
    ],
  },
  {
    title: "Campaigns",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/coupons/physical/all-campaigns",
        title: "All Campaigns",
        type: "link",
      },
    ],
  },
  {
    title: "1688/taobao Orders",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      { path: "/1688-taobao-orders/new", title: "New Orders", type: "link" },
      {
        path: "/1688-taobao-orders/purchased",
        title: "Purchased",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/received-in-warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/ready-for-fly",
        title: "Ready for fly",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/bangladesh-customs",
        title: "Bangladesh customs",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/custom-released",
        title: "Custom released",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/paicart-warehouse",
        title: "Paicart warehouse",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/1688-taobao-orders/rejected",
        title: "Rejected",
        type: "link",
      },
      {
        path: "/1688-orders/partial-payment",
        title: "Partial Payment",
        type: "link",
      },
    ],
  },
  {
    title: "Product Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      { path: "/product-request/new", title: "New Requests", type: "link" },
      {
        path: "/product-request/rates-given",
        title: "Rates Provided",
        type: "link",
      },
      {
        path: "/product-request/purchased",
        title: "Purchased",
        type: "link",
      },
      {
        path: "/product-request/received-in-warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/product-request/ready-for-fly",
        title: "Ready for fly",
        type: "link",
      },
      {
        path: "/product-request/bangladesh-customs",
        title: "Bangladesh customs",
        type: "link",
      },
      {
        path: "/product-request/custom-released",
        title: "Custom released",
        type: "link",
      },
      {
        path: "/product-request/paicart-warehouse",
        title: "Paicart warehouse",
        type: "link",
      },
      {
        path: "/product-request/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/product-request/rejected",
        title: "Rejected",
        type: "link",
      },
    ],
  },
  {
    title: "Shipment Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      { path: "/shipment-request/new", title: "New Request", type: "link" },
      {
        path: "/shipment-request/rates-given",
        title: "Rates Provided",
        type: "link",
      },
      {
        path: "/shipment-request/received-in-warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/shipment-request/ready-for-fly",
        title: "Ready for fly",
        type: "link",
      },

      {
        path: "/shipment-request/bangladesh-customs",
        title: "Bangladesh customs",
        type: "link",
      },
      {
        path: "/shipment-request/custom-released",
        title: "Custom released",
        type: "link",
      },
      {
        path: "/shipment-request/paicart-warehouse",
        title: "Paicart warehouse",
        type: "link",
      },
      { path: "/shipment-request/delivered", title: "Delivered", type: "link" },
      { path: "/shipment-request/rejected", title: "Rejected", type: "link" },
    ],
  },

  {
    title: "Ship for me",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        title: "Air",
        type: "sub",
        active: false,
        children: [
          { path: "/ship-for-me/air-china", title: "China", type: "link" },
          { path: "/ship-for-me/air-india", title: "India", type: "link" },
          { path: "/ship-for-me/air-uk", title: "UK", type: "link" },
          { path: "/ship-for-me/air-usa", title: "USA", type: "link" },
          {
            path: "/ship-for-me/air-hongkong",
            title: "Hongkong",
            type: "link",
          },
          {
            path: "/ship-for-me/air-thailand",
            title: "Thailand",
            type: "link",
          },
        ],
      },
      {
        title: "Sea",
        type: "sub",
        active: false,
        children: [
          { path: "/ship-for-me/sea-china", title: "China", type: "link" },
          { path: "/ship-for-me/sea-uk", title: "UK", type: "link" },
          { path: "/ship-for-me/sea-usa", title: "USA", type: "link" },
          {
            path: "/ship-for-me/sea-hongkong",
            title: "Hongkong",
            type: "link",
          },
          {
            path: "/ship-for-me/sea-thailand",
            title: "Thailand",
            type: "link",
          },
        ],
      },
      {
        path: "/ship-for-me-warehouse",
        title: "Warehouse address",
        type: "link",
      },
    ],
  },
  {
    title: "Payment Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/payment-request/pending",
        title: "Ship for me Request",
        type: "link",
      },
      {
        path: "/payment-request-order/pending",
        title: "Order Request",
        type: "link",
      },

      {
        path: "/refund-request-order/pending",
        title: "Refund Request",
        type: "link",
      },
    ],
  },
  {
    title: "Product to order",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/1688-taobao-orders/paid",
        title: "1688/Taobao Orders",
        type: "link",
      },
      {
        path: "/product-request/paid",
        title: "Product Request",
        type: "link",
      },
    ],
  },
  {
    title: "Notices",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/notices",
        title: "All Notices",
        type: "link",
      },
    ],
  },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  },
  {
    title: "Logout",
    path: "/",
    icon: LogIn,
    type: "link",
    active: false,
  },
];
