import axios from "axios";

export const sendNotifications = async (token, message) => {
  console.log("send notification is called!");
  var data = JSON.stringify({
    data: {},
    notification: {
      title: message.title,
      body: message.body,
    },
    // token
    to: `${token}`,
  });

  var config = {
    method: "post",
    url: "https://fcm.googleapis.com/fcm/send",
    headers: {
      Authorization:
        "key=AAAAnW0BhDw:APA91bG5b3voXtdUwTTEa64wTLJw68Hzl6aghvpo4ragnmoQvzJKdvBkNO44Ra57fegGqnf1Ll73Zpls6O4BsD2d4Rcmbuwg9RaGpn_cEJW1PuQs_vpiTPfRe36-BQ9kaTKqiY5fFc2W",
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};
