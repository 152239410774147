import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  updateRefundRequestOrderStatusRedux,
} from "../../actions/index";
import "./makePaymentModal.css";
import { Link } from "react-router-dom";
import {
  getSingleUser,
  getSingleRefundRequestOrder,
} from "../../firebase/firebase.utils";
import { CircleLoader } from "react-spinners";
import Currency from "../localization/currency";
import { sendNotifications } from "../../firebase/fcmRestApi";
class MakePaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndCondition: false,
      loader: false,
      refundAmount: 0,
    };
  }

  handleSubmit = async () => {
    const { userObj, admin, refundRequestObj, rechargeUser, payableOrders } =
      this.props;

    // admin payment korle admin jabe, user payment korle user jabe
    this.setState({
      loader: true,
    });

    await this.props.updateRefundRequestOrderStatusRedux({
      ...refundRequestObj,
      status: "approved",
      admin: admin,
      refundAmount: this.state.refundAmount,
    });
    this.setState({
      loader: false,
    });
    toast.success("Refund is successful");

    const newRequest = await getSingleRefundRequestOrder(
      refundRequestObj.refundId
    );

    const msg = this.createNotification(newRequest);
    console.log(msg);
    const user = await getSingleUser(refundRequestObj.order.userId);
    console.log(user);
    const message = {
      title: msg.title,
      body: msg.body,
    };
    if (user.deviceToken && user.deviceToken.length > 0) {
      user.deviceToken.map((token) => {
        console.log(token);
        sendNotifications(token, message);
      });
    }
    this.props.startToggleModal(null, []);
  };

  createNotification = (refundRequestObj) => {
    let msg;
    if (refundRequestObj.status === "approved") {
      msg = {
        title: `Refund ${refundRequestObj.status}`,
        body: `Your refund request for Refund Id:-${refundRequestObj.refundId} is ${refundRequestObj.status}.`,
      };
      return msg;
    } else {
      msg = {
        title: `Refund ${refundRequestObj.status}`,
        body: `Your refund request for Refund Id:-${refundRequestObj.refundId} is ${refundRequestObj.status}. Please Pay again or contact us for details.`,
      };
      return msg;
    }
  };
  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render = () => {
    const { currency, refundRequestObj } = this.props;

    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_make_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal order-details-modal-container"
              style={{ backgroundColor: "white" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => this.props.startToggleModal(null, [])}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <h2
                            className="h2-xl mb-2 fw-6 pb-2 order-details-header"
                            style={{ borderBottom: "1px solid gainsboro" }}
                          >
                            Give Refund
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div style={{ fontWeight: "bold" }}>
                            Order Id:{" "}
                            {(refundRequestObj &&
                              refundRequestObj.order &&
                              refundRequestObj.order.orderId) ||
                              (refundRequestObj &&
                                refundRequestObj.order &&
                                refundRequestObj.order.bookingId)}
                          </div>
                          <div style={{ fontWeight: "bold" }}>
                            Order Total:{" "}
                            {refundRequestObj &&
                              refundRequestObj.order &&
                              refundRequestObj.order.orderTotal}
                            Tk
                          </div>
                          <div>
                            <label style={{ fontWeight: "bold" }}>
                              Enter refund amount
                            </label>
                            <input
                              className="form-control"
                              style={{ maxWidth: 300, marginTop: -5 }}
                              type="number"
                              name="refundAmount"
                              value={this.state.refundAmount}
                              onChange={this.handleChange}
                              placeholder="Enter refund amount"
                            ></input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirectioin: "row",
                              marginTop: 30,
                            }}
                          >
                            <input
                              className="terms-condition-checkbox"
                              type="checkbox"
                              name="termsAndCondition"
                              checked={this.state.termsAndCondition}
                              onChange={(e) =>
                                this.setState({
                                  termsAndCondition:
                                    !this.state.termsAndCondition,
                                })
                              }
                            ></input>
                            <div className="agree-terms-condition">
                              I checked and giving the refund.
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                &nbsp; For any kind of fault
                              </span>
                              &nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                I will be responsible.
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {this.state.termsAndCondition && (
                              <div className="procced-to-checkout">
                                <button
                                  onClick={() => {
                                    this.handleSubmit();
                                  }}
                                  className="mt-3 btn btn-secondary "
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_payment_popup"
                                  style={{ minWidth: 120, minHeight: 40 }}
                                >
                                  {!this.state.loader && <>Approve Payment</>}
                                  <CircleLoader
                                    loading={this.state.loader}
                                    color="white"
                                    size={15}
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
}
const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.paymentRequestObj);
  return {
    allUsers: state.users.users,
    rechargeUser: ownProps.paymentRequestObj
      ? state.users.users.find(
          (user) => user.uid === ownProps.paymentRequestObj.userId
        )
      : null,
    admin: state.admins.currentAdmin,
    currency: state.users.currency,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    updateRefundRequestOrderStatusRedux,
  })(MakePaymentModal)
);
