import { combineReducers } from "redux";
import setAdminsReducer from "./admins";
import setOrdersReducer from "./orders";
import setPaymentsReducer from "./payments";
import setProductsReducer from "./products";
import setProductRequestsReducer from "./bookings";
import setUsersReducer from "./users";
import chatsReducer from "./chats";
import setD2DRatesReducer from "./d2dRates";
import couponsReducers from "./coupons";
import warehouseReducers from "./warehouses";
import campaignsReducers from "./campaigns";
import categoriesReducer from "./categories";
import setNoticesReducer from "./notices";
import partialsReducers from "./partials";
const rootReducer = combineReducers({
  orders: setOrdersReducer,
  payments: setPaymentsReducer,
  admins: setAdminsReducer,
  products: setProductsReducer,
  categories: categoriesReducer,
  productRequests: setProductRequestsReducer,
  users: setUsersReducer,
  chats: chatsReducer,
  d2dRates: setD2DRatesReducer,
  coupons: couponsReducers,
  partials: partialsReducers,
  warehouses: warehouseReducers,
  campaigns: campaignsReducers,
  notices: setNoticesReducer,
});

export default rootReducer;
