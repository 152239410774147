import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { connect } from "react-redux";
import { getSingleUser } from "../../firebase/firebase.utils";
import {
  getOrderTrackingResultRedux,
  updateProductRequestRedux,
  deleteProductRequestRedux,
} from "../../actions/index";
import { Link } from "react-router-dom";
import man from "../products/physical/plus image.jpeg";
export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      userObj: null,
      productObj: null,
      amount: 0,
      method: "",
      paymentStatus: "",
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };

  getTotal = (payments) => {
    let total = 0;
    if (payments && payments.length > 0) {
      payments.map((payment) => {
        total += parseInt(payment.amount);
      });
    }
    return total;
  };

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.myData.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      myData: updatedData,
    });
    toast.success("Successfully Deleted !");
  };

  renderEditable = (cellInfo) => {
    const { myData } = this.props;
    if (myData.length > 0) {
      const newData = [];
      myData.forEach((order) => {
        //  this is not affecting my output see line 104
        newData.push({
          Date: order ? order.date : "",
          "Booking Id": order ? order.bookingId : "",
          Store: order ? order.store : "",
          Total:
            order && order.orderTotal
              ? `${Math.round(order.orderTotal)}Tk`
              : "",
          "Order Number": order ? order.orderNumber : "",
          "Tracking No": order ? order.trackingNo : "",
        });
      });
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          onBlur={(e) => {
            const data = [...newData];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ myData: data });
          }}
          dangerouslySetInnerHTML={{
            __html: newData[cellInfo.index][cellInfo.column.id],
          }}
        />
      );
    } else {
      return null;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getStatus = (productQuantity) => {
    if (productQuantity < 10) {
      return <i className="fa fa-circle font-danger f-12" />;
    } else if (productQuantity > 50) {
      return <i className="fa fa-circle font-success f-12" />;
    } else {
      return <i className="fa fa-circle font-warning f-12" />;
    }
  };
  getTotalPaid = (order) => {
    let totalPaid = 0;
    if (order.payments && order.payments.length > 0) {
      order.payments.map((paymnet) => {
        totalPaid += parseInt(paymnet.amount);
      });
    }
    return totalPaid;
  };

  getUserName = async (requestObj) => {
    const userObj = await getSingleUser(requestObj.userId);
    if (userObj) {
      this.setState({ userObj });
    } else {
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit = async (event, totalDue) => {
    event.preventDefault();
    const { productObj } = this.state;
    if (this.state.amount == 0) {
      alert("Paid amount must be more than 0");
      return;
    }
    if (this.state.amount > totalDue) {
      alert("Paid amount can't be more than due amount.");
      return;
    }
    console.log(this.state);

    await this.props.updateProductRequestRedux({
      ...this.state.productObj,
      paymentStatus:
        this.state.paymentStatus !== ""
          ? this.state.paymentStatus
          : this.state.productObj.paymentStatus
          ? this.state.productObj.paymentStatus
          : "",
      payments:
        this.state.productObj.payments &&
        this.state.productObj.payments.length > 0
          ? [
              ...this.state.productObj.payments,
              {
                amount: this.state.amount,
                method: this.state.method,

                receivedBy: this.props.currentAdmin.name,
                date: new Date().toLocaleDateString("en-GB"),
              },
            ]
          : [
              {
                amount: this.state.amount,
                method: this.state.method,
                receivedBy: this.props.currentAdmin.name,
                date: new Date().toLocaleDateString("en-GB"),
              },
            ],
    });
    toast.success("successfully updated the payment");

    this.setState({
      checkedValues: [],
      myData: this.props.myData,
      productObj: null,
      method: "",
      amount: "",
    });
    document.getElementById("close-button-for-payment-modal").click();
  };

  getTotalDue = (order) => {
    let totalPaid = 0;
    let totalDue = 0;
    let totalBill = 0;
    if (order.payments && order.payments.length > 0) {
      order.payments.map((paymnet) => {
        totalPaid += parseInt(paymnet.amount);
      });
    }
    if (order.shippingChargeCustomer && order.shippingChargeCustomer != 0) {
      totalBill = order.orderTotal
        ? parseInt(order.orderTotal) +
          parseInt(order.shippingChargeCustomer) +
          parseInt(order.localShipping)
        : 0 +
          parseInt(order.shippingChargeCustomer) +
          parseInt(order.localShipping);
    } else {
      totalBill = order.orderTotal ? order.orderTotal : 0;
    }
    totalDue = parseInt(totalBill) - parseInt(totalPaid);
    return totalDue;
  };

  render() {
    const {
      pageSize,
      myClass,
      multiSelectOption,
      pagination,
      currency,
      orderTracking,
    } = this.props;
    console.log(this.props);
    const { myData } = this.props;
    const { productObj } = this.state;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      myData.forEach((order) => {
        newData.push({
          Date: order ? order.date : "",
          "Booking Id": order ? order.bookingId : "",
          Store: order ? order.store : "",
          Total:
            order && order.orderTotal
              ? `${Math.round(order.orderTotal)}Tk`
              : "",
          "Order Number": order ? order.orderNumber : "",
          "Tracking No": order ? order.trackingNo : "",
        });
      });
    }
    let totalPayableAmount = 0;
    if (productObj) {
      totalPayableAmount =
        parseInt(productObj.orderTotal) +
        parseInt(productObj.otherCost) +
        parseInt(productObj.localShipping) -
        productObj.discount -
        (productObj.refund ? productObj.refund : 0);
    }
    let totalPaid = 0;
    if (productObj && productObj.payments && productObj.payments.length > 0) {
      productObj.payments.map((payment) => {
        totalPaid += parseInt(payment.amount);
      });
    }
    if (!totalPayableAmount) {
      totalPayableAmount = 0;
    }
    let totalPayableAmountIncludingShipping = 0;
    if (productObj) {
      totalPayableAmountIncludingShipping =
        parseInt(totalPayableAmount) +
        parseInt(productObj.shippingChargeCustomer);
    }
    const columns = [];
    for (var key in newData[0]) {
      let editable = this.renderEditable;
      if (key == "image") {
        editable = null;
      }
      if (key == "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }

    if (multiSelectOption == true) {
      columns.push({
        Header: (
          <button
            className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
            onClick={(e) => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                this.handleRemoveRow();
            }}
          >
            Delete
          </button>
        ),
        id: "delete",
        accessor: (str) => "delete",
        sortable: false,
        style: {
          textAlign: "center",
        },
        Cell: (row) => (
          <div>
            <span>
              <input
                type="checkbox"
                name={row.original.id}
                defaultChecked={this.state.checkedValues.includes(
                  row.original.id
                )}
                onChange={(e) => this.selectRow(e, row.original.id)}
              />
            </span>
          </div>
        ),
        accessor: key,
        style: {
          textAlign: "center",
        },
      });
    } else {
      columns.push(
        {
          Header: <b>Payment status</b>,
          id: "orderDetails",
          accessor: (str) => "orderDetails",
          Cell: (row) => {
            const requestObj = myData.find(
              (order) => order.bookingId == row.original["Booking Id"]
            );
            return (
              <div
                style={{
                  background: requestObj
                    ? requestObj.paymentStatus == "Partially Paid"
                      ? "darkorange"
                      : requestObj.paymentStatus == "Not Paid"
                      ? "red"
                      : requestObj.paymentStatus == "Paid"
                      ? "green"
                      : requestObj.paymentStatus == "purchaseLater"
                      ? "gray"
                      : requestObj.paymentStatus == "pending"
                      ? "darkorange"
                      : "red"
                    : "red",
                  color: "white",
                  display: "inline",
                  borderRadius: 5,
                  fontSize: 12,
                  padding: "2px 7px",
                  cursor: "pointer",
                }}
                data-toggle="modal"
                data-target="#paymentModal"
                onClick={() => {
                  this.setState({
                    productObj: requestObj,
                  });
                }}
              >
                {requestObj.paymentStatus || "Not Paid"}
              </div>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          Header: <b>Booking Details</b>,
          id: "orderDetails",
          accessor: (str) => "orderDetails",
          Cell: (row) => {
            const requestObj = myData.find(
              (order) => order.bookingId == row.original["Booking Id"]
            );
            return (
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#detailInfoModal"
                onClick={async () => {
                  this.setState({
                    productObj: requestObj,
                  });
                  let orderTracking2 =
                    await this.props.getOrderTrackingResultRedux(
                      requestObj.trackingNo
                    );
                  console.log(orderTracking2);
                  if (orderTracking2) {
                    this.props.updateProductRequestRedux({
                      ...requestObj,
                      shippingMark: orderTracking2.parcelsArray.map(
                        (parcel) => `${parcel.shippingMark}`
                      ),
                      cargoCompany: "ALG Limited",
                      lotNo: orderTracking2.lotArray.map((lot) => `${lot}`),
                      ctnNo: orderTracking2.parcelsArray.map(
                        (parcel) => `${parcel.parcelId}`
                      ),
                      cargoRate: orderTracking2.parcelsArray.map((parcel) => {
                        if (parcel.ratePerKg) {
                          return `${parcel.ratePerKg} tk/kg`;
                        } else {
                          return "Not given yet";
                        }
                      }),
                      shippingChargeCargo: orderTracking2.parcelsArray.map(
                        (parcel) => {
                          if (parcel.finalTotal) {
                            return `${parcel.finalTotal} Tk`;
                          } else {
                            return "Not given yet";
                          }
                        }
                      ),
                    });
                  }
                }}
              >
                show
              </button>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          Header: <b>User</b>,
          id: "orderDetails",
          accessor: (str) => "orderDetails",
          Cell: (row) => {
            const requestObj = myData.find(
              (request) => request.bookingId == row.original["Booking Id"]
            );

            return (
              <>
                {myData.length > 0 ? (
                  <div
                    style={{ cursor: "pointer", color: "#ff8084" }}
                    onClick={() =>
                      this.props.history.push(
                        `${process.env.PUBLIC_URL}/users/list-user/${requestObj.userId}`
                      )
                    }
                  >
                    {requestObj.displayName}
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          Header: <b>Update</b>,
          id: "delete",
          accessor: (str) => "delete",
          Cell: (row) => {
            return (
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const order = myData.find(
                      (order) => order.bookingId == row.original["Booking Id"]
                    );
                    this.props.startToggleModal(order);
                  }}
                >
                  <i
                    className="fa fa-pencil"
                    style={{
                      width: 35,
                      fontSize: 20,
                      padding: 11,
                      color: "rgb(40, 167, 69)",
                    }}
                  ></i>
                </span>
                {this.props.orderStatus == "new" && (
                  <span
                    style={{ cursor: "pointer" }}
                    data-toggle="modal"
                    data-target="#deleteModal"
                    onClick={() => {
                      const order = myData.find(
                        (order) => order.bookingId == row.original["Booking Id"]
                      );
                      this.setState({
                        productObj: order,
                      });
                    }}
                  >
                    <i
                      className="fa fa-trash"
                      style={{
                        width: 35,
                        fontSize: 20,
                        padding: 11,
                        color: "red",
                      }}
                    ></i>
                  </span>
                )}
              </div>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          Header: <b>Invoice</b>,
          id: "delete",
          accessor: (str) => "delete",
          Cell: (row) => {
            const order = myData.find(
              (order) => order.bookingId == row.original["Booking Id"]
            );
            return (
              <Link
                to={`${process.env.PUBLIC_URL}/pages/dashboard/buy-and-ship-for/invoice/${order.bookingId}?productRequest=true`}
                target="_blank"
              >
                <div
                  style={{
                    background: "rgb(163, 66, 43)",
                    color: "white",
                    display: "inline",
                    borderRadius: 5,
                    fontSize: 12,
                    padding: "2px 7px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  invoice
                </div>
              </Link>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        }
      );
    }

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ padding: "0px 15px" }}></div>

                {productObj && productObj.warehouse && (
                  <div style={{ fontSize: 11 }}>
                    Warehouse Address:{" "}
                    <span style={{ color: "gray", fontSize: 11 }}>
                      {productObj && productObj.warehouse}
                    </span>
                  </div>
                )}

                {productObj && (
                  <div>
                    {" "}
                    <div style={{ fontWeight: "bold" }}>
                      Store: {productObj.store}
                    </div>
                    <div>
                      Product Link:{" "}
                      <a href={productObj.productUrl} target="_blank">
                        <span style={{ color: "#ff8084" }}>
                          {productObj.productUrl}
                        </span>
                      </a>
                    </div>
                    <table className="table table-bordered table-striped table-hover">
                      <tbody>
                        <tr>
                          <td>
                            <img
                              style={{
                                height: 70,
                                width: 70,
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                              }}
                              src={
                                productObj.imageUrl ? productObj.imageUrl : man
                              }
                            />
                          </td>
                          <td>{productObj.productVariant}</td>
                          <td>{productObj.productType}</td>
                          <td>
                            Shipping Rate: {productObj.shippingRate} tk/kg
                          </td>
                          <td>
                            {parseFloat(productObj.unitPrice).toFixed(2)} Tk
                          </td>
                          <td>{productObj.productQuantity}pcs </td>

                          <td>
                            {parseFloat(
                              productObj.unitPrice * productObj.productQuantity
                            ).toFixed(2)}
                            Tk
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                <div
                  className="row"
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div className="col">
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Purchasing Information
                    </div>

                    <div className="category-list-shipping">
                      Purchase Link:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.purchaseLink}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Purchasing supplier:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.purchasingSupplier}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Purchasing supplier (Payment method):{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.purchasingSupplierPayment}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Purchasing supplier (QR code):{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.purchasingSupplierQr}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Purchasing RMB:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.purchasingRmb
                          ? productObj.purchasingRmb
                          : 0}{" "}
                        rmb
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Exchange Rate:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.exchangeRate
                          ? productObj.exchangeRate
                          : 0}
                        Tk
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total Buying Cost:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj &&
                        productObj.exchangeRate &&
                        productObj.purchasingRmb
                          ? parseInt(
                              productObj.exchangeRate * productObj.purchasingRmb
                            )
                          : 0}
                        Tk
                      </span>
                    </div>

                    <div
                      className="category-list-shipping"
                      style={{ marginTop: 20 }}
                    >
                      Coupon Used:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.couponUsed
                          ? productObj.couponUsed.name
                          : "none"}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Actual Order Total:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && parseInt(productObj.actualTotal)}
                        Tk
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Coupon Amount:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && productObj.couponAmount
                          ? productObj.couponAmount
                          : "0"}
                        Tk
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Discount on (pay now %):{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj &&
                        productObj.offer &&
                        productObj.offer !== 0
                          ? `${Math.round(
                              productObj.actualTotalAfterCoupon *
                                (productObj.offer.split("%")[0] / 100)
                            )}
                                  Tk`
                          : "0Tk"}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total Paid:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && this.getTotalPaid(productObj)}
                        Tk
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total Due:{" "}
                      <span
                        style={{
                          fontWeight: "lighter",
                          textTransform: "capitalize",
                        }}
                      >
                        {productObj && this.getTotalDue(productObj)}
                        Tk
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Shipping Timeline
                    </div>
                    <div className="category-list-shipping">
                      Ordered:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.date}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Purchased:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["PurchasedDate"]}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Abroad Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Received in WarehouseDate"]}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Ready for fly:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Ready for flyDate"]}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Bangladesh customs:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj
                          ? productObj["Bangladesh customsDate"]
                            ? productObj["Bangladesh customsDate"]
                            : productObj["Bangladesh warehouseDate"]
                            ? productObj["Bangladesh warehouseDate"]
                            : ""
                          : ""}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Custom released:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Bangladesh warehouseDate"]}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Paicart Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["Paicart warehouseDate"]}
                      </span>
                    </div>
                    <div
                      className="category-list-shipping"
                      style={{ marginBottom: 20 }}
                    >
                      Delivered:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj["DeliveredDate"]}
                      </span>
                    </div>

                    <div
                      className="category-list-shipping"
                      style={{ fontSize: 11 }}
                    >
                      Abroad Warehouse Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.abroadWarehouseAddress}
                      </span>
                    </div>
                    <div
                      className="category-list-shipping"
                      style={{ fontSize: 11 }}
                    >
                      Bangladesh Warehouse Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.bangladeshWarehouseAddress}
                      </span>
                    </div>
                    <div
                      className="category-list-shipping"
                      style={{ fontSize: 11 }}
                    >
                      Delivered through:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.deliveredBy}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Shipping Information
                    </div>
                    <div className="category-list-shipping">
                      Customer Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.displayName}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Shipping mark:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.shippingMark &&
                          `${productObj.shippingMark}`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Tracking No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.trackingNo}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Cargo company:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.cargoCompany}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Lot Number:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.lotNo &&
                          `${productObj.lotNo}`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Carton number:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.ctnNo &&
                          `${productObj.ctnNo}`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Received weight:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.receivedWeight &&
                          `${productObj.receivedWeight}`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Cargo rate:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.cargoRate &&
                          `${productObj.cargoRate}`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Customs & Shipping rate:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.customerRate &&
                          `${productObj.customerRate}Tk`}
                      </span>
                    </div>

                    <div className="category-list-shipping">
                      Total shipping charge (cargo company):{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.shippingChargeCargo &&
                          `${productObj.shippingChargeCargo}`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total shipping charge (customer):{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj &&
                          productObj.shippingChargeCustomer &&
                          `${productObj.shippingChargeCustomer}Tk`}
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Total Paid (customer):{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && this.getTotal(productObj.payments)}Tk
                      </span>
                    </div>
                    <div className="category-list-shipping">
                      Refund note:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.refundNote}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        paddingLeft: 7,
                        color: "#18768f",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Parcel Info (caro company)
                    </div>
                    {orderTracking ? (
                      <>
                        <div className="category-list-shipping">
                          Cargo company:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            ALG LIMITED
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Customer:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.userObj.userId}-
                            {orderTracking.userObj.displayName}
                          </span>
                        </div>

                        <div className="category-list-shipping">
                          Lot No:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.lotArray.map((lot) => `${lot},`)}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Lot status:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.lotObj.shipmentStatus}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Parcel status:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray[0].parcelStatus ||
                              orderTracking.lotObj.shipmentStatus}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Carton No:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.parcelId},`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Received Date:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.dateofWarehouseReceive},`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Shipping Mark:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.shippingMark},`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Product Name:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.productName},`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Product Contains:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.productType},`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Total CBM:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.totalCbm},`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Total weight:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map(
                              (parcel) => `${parcel.grossWeight} kg,`
                            )}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Rate/kg:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map((parcel) => {
                              if (parcel.ratePerKg) {
                                return `${parcel.ratePerKg} tk/kg,`;
                              } else {
                                return "Not given yet";
                              }
                            })}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Other charge:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map((parcel) => {
                              if (parcel.otherCharges) {
                                return `${parcel.otherCharges} Tk,`;
                              } else {
                                return "Not given yet";
                              }
                            })}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Packaging cost:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map((parcel) => {
                              if (parcel.packagingCost) {
                                return `${parcel.packagingCost} Tk,`;
                              } else {
                                return "Not given yet";
                              }
                            })}
                          </span>
                        </div>
                        <div className="category-list-shipping">
                          Final Total:{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            {orderTracking.parcelsArray.map((parcel) => {
                              if (parcel.finalTotal) {
                                return `${parcel.finalTotal} Tk,`;
                              } else {
                                return "Not given yet";
                              }
                            })}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div style={{ marginLeft: 8, color: "gray" }}>
                        No shipping data available.{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="paymentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div
                    className="col"
                    style={{
                      fontWeight: "bold",
                      borderBottom: "1px solid gainsboro",
                      paddingBottom: 5,
                    }}
                  >
                    Make New Payment
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <div
                    className="row"
                    style={{
                      marginBottom: 10,
                      borderBottom: "1px solid gainsboro",
                    }}
                  >
                    <div className="col">
                      <div
                        style={{
                          paddingLeft: 7,
                          color: "#18768f",
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                      >
                        Order Information
                      </div>

                      <div className="category-list-shipping">
                        Order Actual Total:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.actualTotal} Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Coupon used:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.couponUsed
                            ? productObj.couponUsed.name
                            : "None"}{" "}
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Coupon Amount:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          -
                          {productObj && productObj.couponAmount
                            ? productObj.couponAmount
                            : 0}{" "}
                          Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Offer (%):{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.offer
                            ? productObj.offer
                            : "0%"}
                        </span>
                      </div>

                      <div className="category-list-shipping">
                        Order Total Cost:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.orderTotal} Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Local Shipping cost (china):{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.localShipping} Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Packaging & other Cost:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.otherCost}
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Refund Amount:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.refund}
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Discount:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.discount}
                        </span>
                      </div>

                      <div className="category-list-shipping">
                        Total Payable amount(without Shipping):{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {totalPayableAmount}Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Received Weight:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.receivedWeight}kg
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Customs & Shipping Rate:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.customerRate} Tk/Kg
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Total Shipping Charge:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {productObj && productObj.shippingChargeCustomer} Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Total Payable amount(including Shipping):{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {totalPayableAmountIncludingShipping
                            ? totalPayableAmountIncludingShipping
                            : 0}
                          Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Total Paid:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {totalPaid}Tk
                        </span>
                      </div>
                      <div className="category-list-shipping">
                        Total Due:{" "}
                        <span
                          style={{
                            fontWeight: "lighter",
                            textTransform: "capitalize",
                          }}
                        >
                          {totalPayableAmountIncludingShipping
                            ? totalPayableAmountIncludingShipping - totalPaid
                            : 0}
                          Tk
                        </span>
                      </div>

                      <div className="category-list-shipping">
                        Payment Status:{" "}
                        <span
                          style={{
                            padding: "1px 6px",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: 5,
                            background: productObj
                              ? productObj.paymentStatus == "Partially Paid"
                                ? "darkorange"
                                : productObj.paymentStatus == "Not Paid"
                                ? "red"
                                : productObj.paymentStatus == "Paid"
                                ? "green"
                                : productObj.paymentStatus == "purchaseLater"
                                ? "gray"
                                : productObj.paymentStatus == "pending"
                                ? "darkorange"
                                : "red"
                              : "red",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            this.setState({
                              productObj,
                            });
                          }}
                        >
                          {(productObj && productObj.paymentStatus) ||
                            "Not Paid"}
                        </span>
                      </div>

                      <div className="category-list-shipping">
                        Order Status:{" "}
                        <span
                          style={{
                            padding: 10,
                            fontWeight: "bold",
                            paddingTop: 0,
                            color: "white",
                            padding: "3px 7px",
                            borderRadius: 5,
                            backgroundColor: productObj
                              ? productObj.orderStatus == "pending"
                                ? "gainsboro"
                                : productObj.orderStatus == "Purchased"
                                ? "gray"
                                : productObj.orderStatus ==
                                  "Received in Warehouse"
                                ? "orange"
                                : productObj.orderStatus == "Ready for fly"
                                ? "darkorange"
                                : productObj.orderStatus == "Bangladesh customs"
                                ? "chocolate"
                                : productObj.orderStatus == "Paicart warehouse"
                                ? "green"
                                : productObj.orderStatus ==
                                  "Bangladesh warehouse"
                                ? "#00a3a3"
                                : productObj.orderStatus == "Delivered"
                                ? "darkgreen"
                                : "red"
                              : "white",
                            textAlign: "center",
                          }}
                        >
                          {productObj && productObj.orderStatus}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <form
                      onSubmit={(e) =>
                        this.handleSubmit(
                          e,
                          totalPayableAmountIncludingShipping - totalPaid
                        )
                      }
                      className="rounded-field mt-2"
                    >
                      <div className="form-row mb-2">
                        <div className="col">
                          <label
                            style={{
                              marginBottom: 0,
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            Payment Method
                          </label>

                          <select
                            title=""
                            name="method"
                            className="custom-select"
                            aria-required="true"
                            aria-invalid="false"
                            onChange={this.handleChange}
                            style={{ fontSize: ".8rem" }}
                            value={this.state.method}
                            required
                          >
                            <option value="">Select Method</option>
                            <option value="Cash">Cash</option>
                            <option value="Bkash">Bkash</option>
                            <option value="Nagad">Nagad</option>
                            <option value="Rocket">Rocket</option>
                            <option value="City">City Bank</option>
                          </select>
                        </div>
                        <div className="col">
                          <label
                            style={{
                              marginBottom: 0,
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            Paid amount
                          </label>

                          <input
                            type="number"
                            name="amount"
                            className="form-control"
                            placeholder="Enter amount"
                            style={{ fontSize: "1rem" }}
                            onChange={this.handleChange}
                            value={this.state.amount}
                            required
                          />
                        </div>
                        <div className="col">
                          <label
                            style={{
                              marginBottom: 0,
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            Payment Status
                          </label>

                          <select
                            title=""
                            name="paymentStatus"
                            className="custom-select"
                            aria-required="true"
                            aria-invalid="false"
                            onChange={this.handleChange}
                            style={{ fontSize: ".8rem" }}
                            value={this.state.paymentStatus}
                            required
                          >
                            <option value="">Select Payment Status</option>
                            <option value="Partially Paid">
                              Partially Paid
                            </option>
                            <option value="Paid">Full Paid</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-row">
                        <div
                          className="col pt-3"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            type="submit"
                            className="btn"
                            style={{
                              background: "rgb(0, 37, 76)",
                              color: "white",
                            }}
                          >
                            Update
                            <i className="icofont-rounded-right"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  {this.state.productObj &&
                    this.state.productObj.payments &&
                    this.state.productObj.payments.length > 0 && (
                      <div className="col">
                        <div
                          className="row"
                          style={{
                            height: 1,
                            width: "100%",
                            background: "gainsboro",
                            marginTop: 20,
                            marginBottom: 20,
                            marginRight: 0,
                            marginLeft: 0,
                          }}
                        ></div>
                        <div
                          style={{
                            paddingLeft: 0,
                            color: "#18768f",
                            fontWeight: "bold",
                          }}
                        >
                          Previous Payments
                        </div>
                        <table className="table table-bordered table-striped table-hover">
                          <thead
                            style={{ background: "#18768f", color: "white" }}
                          >
                            <tr>
                              <td style={{ fontWeight: "bold" }}>Date</td>
                              <td style={{ fontWeight: "bold" }}>Method</td>
                              <td style={{ fontWeight: "bold" }}>Amount</td>

                              <td style={{ fontWeight: "bold" }}>
                                Approved by
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.productObj.payments.map((payment) => (
                              <tr>
                                <td>{payment.date}</td>
                                <td>{payment.method}</td>
                                <td>{payment.amount}Tk</td>
                                <td>{payment.receivedBy}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  id="close-button-for-payment-modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div
                    className="col"
                    style={{
                      fontWeight: "bold",

                      paddingBottom: 5,
                    }}
                  >
                    Are you sure you want to delete this order?
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn "
                  style={{ backgroundColor: "red", color: "white" }}
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.deleteProductRequestRedux(productObj);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allUsers: state.users.users,
    currency: state.users.currency,
    orderTracking: state.orders.orderTracking,
    currentAdmin: state.admins.currentAdmin,
  };
};
export default connect(mapStateToProps, {
  getOrderTrackingResultRedux,
  updateProductRequestRedux,
  deleteProductRequestRedux,
})(Datatable);
