import React, { Component, Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";
import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
} from "react-feather";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";
import CanvasJSReact from "../assets/canvas/canvasjs.react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Pie, Doughnut, Bar, Line } from "react-chartjs-2";
import {
  pieOptions,
  doughnutOption,
  lineOptions,
  buyOption,
  employeeData,
  employeeOptions,
} from "../constants/chartData";

// image import
import user2 from "../assets/images/dashboard/user2.jpg";
import user1 from "../assets/images/dashboard/user1.jpg";
import man from "../assets/images/dashboard/man.jpg";
import user from "../assets/images/dashboard/user.png";
import designer from "../assets/images/dashboard/designer.jpg";
import {
  getAllShipmentRequestsToChangeStatus,
  getAllOrdersToChangeStatus,
  updateOrderApi,
  updateShipmentRequest,
} from "../firebase/firebase.utils";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader1: false,
      loader2: false,
    };
  }
  componentDidMount = async () => {};

  getUpdatedShipmentRequestStatus = async () => {
    const allRequests = await getAllShipmentRequestsToChangeStatus();
    const checkStatusArray = allRequests
      .filter((request) => request.status !== "Pending")
      .filter((request) => request.trackingNo);
    console.log(checkStatusArray);
    checkStatusArray.forEach(async (request) => {
      const resultObj = await Axios.get(
        `https://alglimited.com/api/v1/orderTracking-alg/${request.trackingNo}`
      );
      if (resultObj && resultObj.data) {
        console.log(resultObj.data);
        let updatedStatus = {};
        let date = new Date().toLocaleDateString("en-GB");
        let date2 = new Date().toLocaleTimeString("en-GB");

        if (resultObj.data.parcelsArray[0].parcelStatus == "Delivered") {
          updatedStatus = {
            status: "Paicart warehouse",
            shipmentStatusScore: 6,
            "Paicart warehouseDate": request["Paicart warehouseDate"]
              ? request["Paicart warehouseDate"]
              : date,
            "Paicart warehouseTime": request["Paicart warehouseTime"]
              ? request["Paicart warehouseTime"]
              : date2,
          };
        } else if (resultObj.data.lotObj.shipmentStatus == "Local Warehouse") {
          updatedStatus = {
            status: "Bangladesh warehouse",
            shipmentStatusScore: 5,
            "Bangladesh warehouseDate": request["Bangladesh warehouseDate"]
              ? request["Bangladesh warehouseDate"]
              : date,
            "Bangladesh warehouseTime": request["Bangladesh warehouseTime"]
              ? request["Bangladesh warehouseTime"]
              : date2,
          };
        } else if (
          resultObj.data.lotObj.shipmentStatus == "Bangladesh Customs"
        ) {
          updatedStatus = {
            status: "Bangladesh customs",
            shipmentStatusScore: 5,
            "Bangladesh customsDate": request["Bangladesh customsDate"]
              ? request["Bangladesh customsDate"]
              : date,
            "Bangladesh customsTime": request["Bangladesh customsTime"]
              ? request["Bangladesh customsTime"]
              : date2,
          };
        } else if (resultObj.data.lotObj.shipmentStatus == "Ready to Fly") {
          updatedStatus = {
            status: "Ready for fly",
            shipmentStatusScore: 4,
            "Ready for flyDate": request["Ready for flyDate"]
              ? request["Ready for flyDate"]
              : date,
            "Ready for flyTime": request["Ready for flyTime"]
              ? request["Ready for flyTime"]
              : date2,
          };
        } else if (
          resultObj.data.lotObj.shipmentStatus == "Abroad Customs" ||
          resultObj.data.lotObj.shipmentStatus == "Abroad Warehouse"
        ) {
          updatedStatus = {
            status: "Received in Warehouse",
            shipmentStatusScore: 3,
            "Received in WarehouseDate": request["Received in WarehouseDate"]
              ? request["Received in WarehouseDate"]
              : date,
            "Received in WarehouseTime": request["Received in WarehouseTime"]
              ? request["Received in WarehouseTime"]
              : date2,
          };
        }
        console.log(updatedStatus);
        await updateShipmentRequest({ ...request, ...updatedStatus });
      }
    });
  };
  getUpdatedOrderStatus = async () => {
    const allOrders = await getAllOrdersToChangeStatus();
    const checkStatusArray = allOrders
      .filter((order) => order.orderStatus !== "pending")
      .filter((order) => order.trackingNo);
    console.log(checkStatusArray);
    checkStatusArray.forEach(async (request) => {
      const resultObj = await Axios.get(
        `https://alglimited.com/api/v1/orderTracking-alg/${request.trackingNo}`
      );
      if (resultObj && resultObj.data) {
        console.log(resultObj.data);
        let updatedStatus = {};
        let date = new Date().toLocaleDateString("en-GB");
        let date2 = new Date().toLocaleTimeString("en-GB");

        if (resultObj.data.parcelsArray[0].parcelStatus == "Delivered") {
          updatedStatus = {
            orderStatus: "Paicart warehouse",
            shipmentStatusScore: 6,
            "Paicart warehouseDate": request["Paicart warehouseDate"]
              ? request["Paicart warehouseDate"]
              : date,
            "Paicart warehouseTime": request["Paicart warehouseTime"]
              ? request["Paicart warehouseTime"]
              : date2,
          };
        } else if (resultObj.data.lotObj.shipmentStatus == "Local Warehouse") {
          updatedStatus = {
            orderStatus: "Bangladesh warehouse",
            shipmentStatusScore: 5,
            "Bangladesh warehouseDate": request["Bangladesh warehouseDate"]
              ? request["Bangladesh warehouseDate"]
              : date,
            "Bangladesh warehouseTime": request["Bangladesh warehouseTime"]
              ? request["Bangladesh warehouseTime"]
              : date2,
          };
        } else if (
          resultObj.data.lotObj.shipmentStatus == "Bangladesh Customs"
        ) {
          updatedStatus = {
            orderStatus: "Bangladesh customs",
            shipmentStatusScore: 5,
            "Bangladesh customsDate": request["Bangladesh customsDate"]
              ? request["Bangladesh customsDate"]
              : date,
            "Bangladesh customsTime": request["Bangladesh customsTime"]
              ? request["Bangladesh customsTime"]
              : date2,
          };
        } else if (resultObj.data.lotObj.shipmentStatus == "Ready to Fly") {
          updatedStatus = {
            orderStatus: "Ready for fly",
            shipmentStatusScore: 4,
            "Ready for flyDate": request["Ready for flyDate"]
              ? request["Ready for flyDate"]
              : date,
            "Ready for flyTime": request["Ready for flyTime"]
              ? request["Ready for flyTime"]
              : date2,
          };
        } else if (
          resultObj.data.lotObj.shipmentStatus == "Abroad Customs" ||
          resultObj.data.lotObj.shipmentStatus == "Abroad Warehouse"
        ) {
          updatedStatus = {
            orderStatus: "Received in Warehouse",
            shipmentStatusScore: 3,
            "Received in WarehouseDate": request["Received in WarehouseDate"]
              ? request["Received in WarehouseDate"]
              : date,
            "Received in WarehouseTime": request["Received in WarehouseTime"]
              ? request["Received in WarehouseTime"]
              : date2,
          };
        }
        console.log(updatedStatus);
        await updateOrderApi({ ...request, ...updatedStatus });
      }
    });
  };
  getTotalPaymentAmount = (paymentsArray) => {
    var totalAmount = paymentsArray.reduce((acc, payments) => {
      payments.payments.forEach((payment) => {
        acc += parseInt(payment.amount);
      });
      return acc;
    }, 0);
    return totalAmount;
  };

  render() {
    const { allPayments, allAdmins, allOrders, allProducts } = this.props;

    const lineData = {
      labels: ["100", "200", "300", "400", "500", "600", "700", "800"],
      datasets: [
        {
          lagend: "none",
          data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
          borderColor: "#ff8084",
          backgroundColor: "#ff8084",
          borderWidth: 2,
        },
        {
          lagend: "none",
          data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
          borderColor: "#a5a5a5",
          backgroundColor: "#a5a5a5",
          borderWidth: 2,
        },
      ],
    };

    const buyData = {
      labels: ["", "10", "20", "30", "40", "50"],
      datasets: [
        {
          backgroundColor: "transparent",
          borderColor: "#13c9ca",
          data: [20, 5, 80, 10, 100, 15],
        },
        {
          backgroundColor: "transparent",
          borderColor: "#a5a5a5",
          data: [0, 50, 20, 70, 30, 27],
        },
        {
          backgroundColor: "transparent",
          borderColor: "#ff8084",
          data: [0, 30, 40, 10, 86, 40],
        },
      ],
    };

    const doughnutOptions = {
      title: "",
      pieHole: 0.35,
      pieSliceBorderColor: "none",
      colors: ["#ff8084", "#13c9ca", "#a5a5a5"],
      legend: {
        position: "none",
      },
      pieSliceText: "none",
      tooltip: {
        trigger: "none",
      },
      animation: {
        startup: true,
        easing: "linear",
        duration: 1500,
      },
      chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
      enableInteractivity: false,
    };
    const pieOptions = {
      title: "",
      pieHole: 1,
      slices: [
        {
          color: "#ff8084",
        },
        {
          color: "#13c9ca",
        },
        {
          color: "#f0b54d",
        },
      ],
      tooltip: {
        showColorCode: false,
      },
      chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
      legend: "none",
    };
    const LineOptions = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#ff8084"],
      legend: "none",
    };
    const LineOptions1 = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#13c9ca"],
      chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
      legend: "none",
    };
    const LineOptions2 = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#f5ce8a"],
      chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
      legend: "none",
    };
    const LineOptions3 = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#a5a5a5"],
      chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
      legend: "none",
    };
    return (
      <Fragment>
        <Breadcrumb title="Dashboard" parent="Dashboard" />
        <div className="container-fluid">
          <div className="row" style={{ paddingLeft: 10, marginBottom: 20 }}>
            <button
              className="btn"
              style={{ backgroundColor: "beige", border: "1px solid gray" }}
              onClick={async () => {
                if (this.state.loader1) {
                  return;
                }
                this.setState({
                  loader1: true,
                });
                await this.getUpdatedShipmentRequestStatus();
                this.setState({
                  loader1: false,
                });
                alert("All Shipment request updated.");
              }}
            >
              {this.state.loader1 ? (
                <div style={{ paddingLeft: 120, paddingRight: 120 }}>
                  <ClipLoader
                    color={"black"}
                    loading={this.state.loader1}
                    size={16}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                "Update All Shipment Request"
              )}
            </button>
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: "beige",
                border: "1px solid gray",
                marginLeft: 10,
              }}
              onClick={async () => {
                if (this.state.loader2) {
                  return;
                }
                this.setState({
                  loader2: true,
                });
                await this.getUpdatedOrderStatus();
                this.setState({
                  loader2: false,
                });
                alert("All Orders updated.");
              }}
            >
              {this.state.loader2 ? (
                <div style={{ paddingLeft: 120, paddingRight: 120 }}>
                  <ClipLoader
                    color={"black"}
                    loading={this.state.loader2}
                    size={16}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                "Update All Orders"
              )}
            </button>
          </div>
          <div className="row">
            {this.props.currentAdmin &&
            this.props.currentAdmin.status == "admin" ? (
              <>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card o-hidden widget-cards">
                    <div className="bg-warning card-body">
                      <div className="media static-top-widget row">
                        <div className="icons-widgets col-4">
                          <div className="align-self-center text-center">
                            <Navigation className="font-warning" />
                          </div>
                        </div>
                        <div className="media-body col-8">
                          <span className="m-0">Earnings</span>
                          <h3 className="mb-0">
                            Tk{" "}
                            <CountUp
                              className="counter"
                              end={this.getTotalPaymentAmount(allPayments)}
                            />
                            <small>so far</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card o-hidden  widget-cards">
                    <div className="bg-secondary card-body">
                      <div className="media static-top-widget row">
                        <div className="icons-widgets col-4">
                          <div className="align-self-center text-center">
                            <Box className="font-secondary" />
                          </div>
                        </div>
                        <div className="media-body col-8">
                          <span className="m-0">Products</span>
                          <h3 className="mb-0">
                            <CountUp
                              className="counter"
                              end={allProducts ? allProducts.length : ""}
                            />
                            <small>available</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card o-hidden widget-cards">
                    <div className="bg-primary card-body">
                      <div className="media static-top-widget row">
                        <div className="icons-widgets col-4">
                          <div className="align-self-center text-center">
                            <MessageSquare className="font-primary" />
                          </div>
                        </div>
                        <div className="media-body col-8">
                          <span className="m-0">Orders</span>
                          <h3 className="mb-0">
                            <CountUp
                              className="counter"
                              end={allOrders ? allOrders.length : ""}
                            />
                            <small>total placed</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card o-hidden widget-cards">
                    <div className="bg-danger card-body">
                      <div className="media static-top-widget row">
                        <div className="icons-widgets col-4">
                          <div className="align-self-center text-center">
                            <Users className="font-danger" />
                          </div>
                        </div>
                        <div className="media-body col-8">
                          <span className="m-0">Suppliers</span>
                          <h3 className="mb-0">
                            <CountUp
                              className="counter"
                              end={allAdmins ? allAdmins.length : ""}
                            />
                            <small> persons</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Market Value</h5>
                </div>
                <div className="card-body">
                  <div className="market-chart">
                    <Bar
                      data={lineData}
                      options={lineOptions}
                      width={778}
                      height={308}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Pending Orders</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Order ID</th>
                          <th scope="col">Order Total</th>
                          <th scope="col">Customer</th>
                          <th scope="col">Phone</th>
                        </tr>
                      </thead>
                      {this.props.currentAdmin &&
                      this.props.currentAdmin.status == "admin" ? (
                        <tbody>
                          {allOrders
                            ? allOrders
                                .filter(
                                  (order) => order.status === "order_pending"
                                )
                                .slice(0, 5)
                                .map((order) => (
                                  <tr key={order.orderId}>
                                    <td className="font-danger">
                                      {order.orderId}
                                    </td>
                                    <td className="font-danger">
                                      Tk {order.paymentStatus.total}
                                    </td>
                                    <td className="font-danger">
                                      {order.otherInformation.first_name}{" "}
                                      {order.otherInformation.last_name}
                                    </td>
                                    <td className="font-danger">
                                      {order.otherInformation.phone}
                                    </td>
                                  </tr>
                                ))
                            : ""}
                        </tbody>
                      ) : (
                        <tbody>
                          {allOrders
                            ? allOrders
                                .filter((order) => order.status === "ordered")
                                .filter(
                                  (order) =>
                                    order.orderTo === this.props.currentAdmin &&
                                    this.props.currentAdmin.name
                                )
                                .slice(0, 5)
                                .map((order) => (
                                  <tr key={order.orderId}>
                                    <td className="font-danger">
                                      {order.orderId}
                                    </td>
                                    <td className="font-danger">
                                      Tk {order.paymentStatus.total}
                                    </td>
                                    <td className="font-danger">
                                      {order.otherInformation.first_name}{" "}
                                      {order.otherInformation.last_name}
                                    </td>
                                    <td className="font-danger">
                                      {order.otherInformation.phone}
                                    </td>
                                  </tr>
                                ))
                            : ""}
                        </tbody>
                      )}
                    </table>
                    {this.props.currentAdmin &&
                    this.props.currentAdmin.status == "admin" ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}/sales/order_pending`}
                        className="btn btn-primary"
                      >
                        View All Pending Orders
                      </Link>
                    ) : (
                      <Link
                        to={`${process.env.PUBLIC_URL}/sales/ordered`}
                        className="btn btn-primary"
                      >
                        View All Pending Orders
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.props.currentAdmin &&
            this.props.currentAdmin.status == "admin" ? (
              <>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                    <div className="card-header">
                      <h6>Total Sales</h6>
                      <div className="row">
                        <div className="col-6">
                          <div className="small-chartjs">
                            <div
                              className="flot-chart-placeholder"
                              id="simple-line-chart-sparkline-3"
                            >
                              <Chart
                                height={"60px"}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                  ["x", "time"],
                                  [0, 20],
                                  [1, 5],
                                  [2, 120],
                                  [3, 10],
                                  [4, 140],
                                  [5, 15],
                                ]}
                                options={LineOptions}
                                legend_toggle
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="value-graph">
                            <h3>
                              42%{" "}
                              <span>
                                <i className="fa fa-angle-up font-primary"></i>
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body">
                          <span>Sales Last Month</span>
                          <h2 className="mb-0">9054</h2>
                          <p>
                            0.25%{" "}
                            <span>
                              <i className="fa fa-angle-up"></i>
                            </span>
                          </p>
                          <h5 className="f-w-600 f-16">
                            Gross sales of August
                          </h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting
                          </p>
                        </div>
                        <div className="bg-primary b-r-8">
                          <div className="small-box">
                            <Briefcase />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                    <div className="card-header">
                      <h6>Total purchase</h6>
                      <div className="row">
                        <div className="col-6">
                          <div className="small-chartjs">
                            <div
                              className="flot-chart-placeholder"
                              id="simple-line-chart-sparkline"
                            >
                              <Chart
                                height={"60px"}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                  ["x", "time"],
                                  [0, 85],
                                  [1, 83],
                                  [2, 90],
                                  [3, 70],
                                  [4, 85],
                                  [5, 60],
                                  [6, 65],
                                  [7, 63],
                                  [8, 68],
                                  [9, 68],
                                  [10, 65],
                                  [11, 40],
                                  [12, 60],
                                  [13, 75],
                                  [14, 70],
                                  [15, 90],
                                ]}
                                options={LineOptions1}
                                legend_toggle
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="value-graph">
                            <h3>
                              20%{" "}
                              <span>
                                <i className="fa fa-angle-up font-secondary"></i>
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body">
                          <span>Monthly Purchase</span>
                          <h2 className="mb-0">2154</h2>
                          <p>
                            0.13%{" "}
                            <span>
                              <i className="fa fa-angle-up"></i>
                            </span>
                          </p>
                          <h5 className="f-w-600 f-16">Avg Gross purchase</h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting
                          </p>
                        </div>
                        <div className="bg-secondary b-r-8">
                          <div className="small-box">
                            <CreditCard />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                    <div className="card-header">
                      <h6>Total cash transaction</h6>
                      <div className="row">
                        <div className="col-6">
                          <div className="small-chartjs">
                            <div
                              className="flot-chart-placeholder"
                              id="simple-line-chart-sparkline-2"
                            >
                              <Chart
                                height={"60px"}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                  ["x", "time"],
                                  [0, 85],
                                  [1, 83],
                                  [2, 90],
                                  [3, 70],
                                  [4, 85],
                                  [5, 60],
                                  [6, 65],
                                  [7, 63],
                                  [8, 68],
                                  [9, 68],
                                  [10, 65],
                                  [11, 40],
                                  [12, 60],
                                  [13, 75],
                                  [14, 70],
                                  [15, 90],
                                ]}
                                options={LineOptions2}
                                legend_toggle
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="value-graph">
                            <h3>
                              28%{" "}
                              <span>
                                <i className="fa fa-angle-up font-warning"></i>
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body">
                          <span>Cash on hand</span>
                          <h2 className="mb-0">4672</h2>
                          <p>
                            0.8%{" "}
                            <span>
                              <i className="fa fa-angle-up"></i>
                            </span>
                          </p>
                          <h5 className="f-w-600 f-16">Details about cash</h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting
                          </p>
                        </div>
                        <div className="bg-warning b-r-8">
                          <div className="small-box">
                            <ShoppingCart />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 xl-50">
                  <div className="card order-graph sales-carousel">
                    <div className="card-header">
                      <h6>Daily Deposits</h6>
                      <div className="row">
                        <div className="col-6">
                          <div className="small-chartjs">
                            <div
                              className="flot-chart-placeholder"
                              id="simple-line-chart-sparkline-1"
                            >
                              <Chart
                                height={"60px"}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                  ["x", "time"],
                                  [0, 85],
                                  [1, 83],
                                  [2, 90],
                                  [3, 70],
                                  [4, 85],
                                  [5, 60],
                                  [6, 65],
                                  [7, 63],
                                  [8, 68],
                                  [9, 68],
                                  [10, 65],
                                  [11, 40],
                                  [12, 60],
                                  [13, 75],
                                  [14, 70],
                                  [15, 90],
                                ]}
                                options={LineOptions3}
                                legend_toggle
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="value-graph">
                            <h3>
                              75%{" "}
                              <span>
                                <i className="fa fa-angle-up font-danger"></i>
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body">
                          <span>Security Deposits</span>
                          <h2 className="mb-0">5782</h2>
                          <p>
                            0.25%{" "}
                            <span>
                              <i className="fa fa-angle-up"></i>
                            </span>
                          </p>
                          <h5 className="f-w-600 f-16">Gross sales of June</h5>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting
                          </p>
                        </div>
                        <div className="bg-danger b-r-8">
                          <div className="small-box">
                            <Calendar />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Buy / Sell</h5>
                    </div>
                    <div className="card-body sell-graph">
                      <Line
                        data={buyData}
                        options={buyOption}
                        width={700}
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="col-xl-6 xl-100">
              <div className="card height-equal">
                <div className="card-header">
                  <h5>Products to Order</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive products-table">
                    <table className="table table-bordernone mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Order Id</th>
                          <th scope="col">Order Total</th>
                          <th scope="col">Supplier</th>
                        </tr>
                      </thead>
                      {this.props.currentAdmin &&
                      this.props.currentAdmin.status == "admin" ? (
                        <tbody>
                          {allOrders
                            ? allOrders
                                .filter((order) => order.status === "ordered")
                                .slice(0, 6)
                                .map((order, i) => (
                                  <tr key={order.orderId}>
                                    <td>{order.orderId}</td>
                                    <td className="font-danger">
                                      Tk {order.paymentStatus.total}
                                    </td>
                                    {i % 2 === 0 ? (
                                      <td className="font-secondary">
                                        {order.orderTo}
                                      </td>
                                    ) : (
                                      <td className="font-primary">
                                        {order.orderTo}
                                      </td>
                                    )}
                                  </tr>
                                ))
                            : ""}
                        </tbody>
                      ) : (
                        <tbody>
                          {allOrders
                            ? allOrders
                                .filter((order) => order.status === "ordered")
                                .filter(
                                  (order) =>
                                    order.orderTo === this.props.currentAdmin &&
                                    this.props.currentAdmin.name
                                )
                                .slice(0, 6)
                                .map((order, i) => (
                                  <tr key={order.orderId}>
                                    <td>{order.orderId}</td>
                                    <td className="font-danger">
                                      Tk {order.paymentStatus.total}
                                    </td>
                                    {i % 2 === 0 ? (
                                      <td className="font-secondary">
                                        {order.orderTo}
                                      </td>
                                    ) : (
                                      <td className="font-primary">
                                        {order.orderTo}
                                      </td>
                                    )}
                                  </tr>
                                ))
                            : ""}
                        </tbody>
                      )}
                    </table>
                    <Link
                      to={`${process.env.PUBLIC_URL}/sales/ordered`}
                      className="btn btn-primary"
                    >
                      view All Ordered items
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card height-equal">
                <div className="card-header">
                  <h5>Empolyee Status</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive products-table">
                    <table className="table table-bordernone mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Skill Level</th>
                          <th scope="col">Experience</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allAdmins
                          ? allAdmins.slice(0, 5).map((admin, i) => (
                              <tr key={admin.adminId}>
                                <td className="bd-t-none u-s-tb">
                                  <div className="align-middle image-sm-size">
                                    <img
                                      className="img-radius align-top m-r-15 rounded-circle blur-up lazyloaded"
                                      src={admin.image ? admin.image : user2}
                                      alt={admin.name}
                                      data-original-title=""
                                      title=""
                                    />
                                    <div className="d-inline-block">
                                      <h6>
                                        {admin.name}{" "}
                                        <span className="text-muted digits">
                                          (
                                          {
                                            admin.successfully_delivered_orders
                                              .length
                                          }
                                          )
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                                <td>{admin.status}</td>
                                <td>
                                  <div className="progress-showcase">
                                    <div
                                      className="progress"
                                      style={{ height: 8 }}
                                    >
                                      {i % 2 === 0 ? (
                                        <div
                                          className="progress-bar bg-primary"
                                          style={{
                                            width:
                                              20 +
                                              admin
                                                .successfully_delivered_orders
                                                .length,
                                          }}
                                          role="progressbar"
                                          aria-valuenow="50"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      ) : (
                                        <div
                                          className="progress-bar bg-secondary"
                                          style={{
                                            width:
                                              20 +
                                              admin
                                                .successfully_delivered_orders
                                                .length,
                                          }}
                                          role="progressbar"
                                          aria-valuenow="50"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="digits">2 Year</td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Sales Status</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-3 col-sm-6 xl-50">
                      <div className="order-graph">
                        <h6>Orders By Location</h6>
                        <div className="chart-block chart-vertical-center">
                          <Chart
                            width={"100%"}
                            height={"180px"}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                              ["Task", "Hours per Day"],
                              ["Saint Lucia", 300],
                              ["Kenya", 50],
                              ["Liberia", 100],
                            ]}
                            options={doughnutOptions}
                            legend_toggle
                          />
                        </div>
                        <div className="order-graph-bottom">
                          <div className="media">
                            <div className="order-color-primary"></div>
                            <div className="media-body">
                              <h6 className="mb-0">
                                Saint Lucia{" "}
                                <span className="pull-right">$157</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-color-secondary"></div>
                            <div className="media-body">
                              <h6 className="mb-0">
                                Kenya <span className="pull-right">$347</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-color-danger"></div>
                            <div className="media-body">
                              <h6 className="mb-0">
                                Liberia<span className="pull-right">$468</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-color-warning"></div>
                            <div className="media-body">
                              <h6 className="mb-0">
                                Christmas Island
                                <span className="pull-right">$742</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-color-success"></div>
                            <div className="media-body">
                              <h6 className="mb-0">
                                Saint Helena{" "}
                                <span className="pull-right">$647</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 xl-50">
                      <div className="order-graph sm-order-space">
                        <h6>Sales By Location</h6>
                        <div className="peity-chart-dashboard text-center">
                          <Chart
                            chartType="PieChart"
                            data={[
                              ["Task", "Hours per Day"],
                              ["Saint Lucia", 300],
                              ["Kenya", 50],
                              ["Liberia", 100],
                            ]}
                            options={pieOptions}
                            graph_id="PieChart"
                            width={"100%"}
                            height={"180px"}
                            legend_toggle
                          />
                        </div>
                        <div className="order-graph-bottom sales-location">
                          <div className="media">
                            <div className="order-shape-primary"></div>
                            <div className="media-body">
                              <h6 className="mb-0 mr-0">
                                Germany <span className="pull-right">25%</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-shape-secondary"></div>
                            <div className="media-body">
                              <h6 className="mb-0 mr-0">
                                Brasil <span className="pull-right">10%</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-shape-danger"></div>
                            <div className="media-body">
                              <h6 className="mb-0 mr-0">
                                United Kingdom
                                <span className="pull-right">34%</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-shape-warning"></div>
                            <div className="media-body">
                              <h6 className="mb-0 mr-0">
                                Australia<span className="pull-right">5%</span>
                              </h6>
                            </div>
                          </div>
                          <div className="media">
                            <div className="order-shape-success"></div>
                            <div className="media-body">
                              <h6 className="mb-0 mr-0">
                                Canada <span className="pull-right">25%</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 xl-100">
                      <div className="order-graph xl-space">
                        <h6>Revenue for last month</h6>
                        <div className="ct-4 flot-chart-container">
                          <Line data={employeeData} options={employeeOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
// javascript:void(0)

const mapStateToProps = (state) => {
  return {
    allOrders: state.orders.orders,
    allPayments: state.payments.payments,
    allAdmins: state.admins.admins,
    allProducts: state.products.products,
    currentAdmin: state.admins.currentAdmin,
  };
};
export default connect(mapStateToProps, null)(Dashboard);
