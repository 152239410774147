const INITIAL_STATE = {
  orders: [],
  ordersApi: [],
  orderTracking: null,
  orderObj: null,
};

const setOrdersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_ORDERS":
      return { ...state, orders: [...action.payload] };
    case "GET_ALL_ORDERS_API_OF_SINGLE_STATUS":
      return { ...state, ordersApi: action.payload };
    case "GET_ORDER_TRACKING_RESULT":
      return { ...state, orderTracking: action.payload };
    case "GET_SINGLE_ORDER_API":
      return { ...state, orderObj: action.payload };
    case "UPDATE_ORDER_API":
      return {
        ...state,
        ordersApi: state.ordersApi.map((order) => {
          if (order.orderId == action.payload.orderId) {
            return action.payload;
          } else {
            return order;
          }
        }),
      };
    case "DELETE_ORDER_API":
      return {
        ...state,
        ordersApi: state.ordersApi.filter(
          (order) => order.orderId !== action.payload.orderId
        ),
      };
    default:
      return { ...state };
  }
};
export default setOrdersReducer;
